import React from "react"
import { Link } from "gatsby"
import Loadable from "@loadable/component"
import Layout from "../components/layout"
import SEO from "../components/seo"


const IndexPage = ({data}) => {

    const HomeTop = Loadable(() => import("../components/hometop"));
    

    return (
        <Layout pageClass='home-page'>
        <SEO title="Home" /> 
            <HomeTop />
            
            <main className='main'>
                <div className="top-strip "></div>
                <section className="find-out-more typography">
                    <h2>Find Out More</h2>
                    <div className="inner">
                        <div className="card">
                            <div className="icon">
                                <img src={data.imageOne.original.src} 
                                title={`Training`} 
                                alt="Turning Tables Training"
                                
                                />
                            </div>
                            <div className="text">
                                <p>Tailor-Made training at your place. </p>
                            </div>
                            <Link className="read-more" to="/training/tailor-made-training">Read More</Link>
                        </div>
                        <div className="card">
                            <div className="icon">
                                <img 
                                    src={data.imageTwo.original.src} 
                                    title={`Tutorials`}
                                    alt="Turning Tables Tutorials" />
                            </div>
                            <div className="text">
                                <p>One on one coaching for your team. </p>
                            </div>
                            <Link className="read-more" to="/training/tutorials-coaching-mentoring">Read More</Link>
                        </div>
                        <div className="card">
                            <div className="icon">
                                <img 
                                    src={data.imageThree.original.src} 
                                    title={`Training Toolbox`} 
                                    alt="Turning Tables Training Toolbox"
                                    />
                            </div>
                            <div className="text">
                                <p>Online videos, presentations and resources. </p>
                            </div>
                            <Link className="read-more" to="/training/toolbox">Read More</Link>
                        </div>
                        <div className="card">
                            <div className="icon">
                                <img 
                                src={data.imageFour.original.src} 
                                title={`On Tour`}
                                alt="Turning Tables On Tour" />
                            </div>
                            <div className="text">
                                <p>Hospitality industry workshops coming to a town near you. </p>
                            </div>
                            <Link className="read-more" to="/on-tour">Read More</Link>
                        </div>
                        <div className="card">
                            <div className="icon">
                                <img 
                                src={data.imageFive.original.src} 
                                title={`Table Talk`}
                                alt="Turning Tables Table Talk" />
                            </div>
                            <div className="text">
                                <p>Training tips, tricks and ideas. </p>
                            </div>
                            <Link className="read-more" to="/table-talk">Read More</Link>
                        </div>
                    </div>
                    
                </section>

        
            
            </main>
        </Layout>
    )
}


export const pageQuery = graphql`
    query {
        imageOne: imageSharp(resolutions: {originalName: {eq: "icon-training.jpg"}}) {
            original {
                src
            }
            
        }
        imageTwo: imageSharp(resolutions: {originalName: {eq: "icon-tutorials.jpg"}}) {
            original {
                src
            }
            
        }
        imageThree: imageSharp(resolutions: {originalName: {eq: "icon-trainingToolbox.jpg"}}) {
            original {
                src
            }
            
        }
        imageFour: imageSharp(resolutions: {originalName: {eq: "icon-onTour.jpg"}}) {
            original {
                src
            }
            
        }
        imageFive: imageSharp(resolutions: {originalName: {eq: "icon-tableTalk.jpg"}}) {
            original {
                src
            }
            
        }

     
    }
`

export default IndexPage
